import {Duration} from "luxon";

export const LEVEL_OPTIONS = [
  {text: 'Max Load', value: 1},
  {text: '75% Load', value: 0.75},
  {text: '50% Load', value: 0.5},
  {text: 'Min Circ', value: 0.25},
];

export const LEVEL_MAP = new Map<number, string>(
    LEVEL_OPTIONS.map((option) => [option.value, option.text]),
);

export const LEVEL_MAP_REVERSE = new Map<string, number>(
    LEVEL_OPTIONS.map((option) => [option.text, option.value]),
);

export const DEFAULT_SNOWMAKING_DURATION = 8;

export const SCHEDULE_UPDATE_WARNING_THRESHOLD = Duration.fromObject({days: -3});

export const UPCOMING_EVENT_COLOR = 7;
export const POTENTIAL_EVENT_COLOR = 8;
