import api from "@/api";
import {DateTime, Interval} from "luxon";
import {SiteConfiguration, SiteProfile, SiteResponseLevel} from "@/components/settings/types";
import {DateTimeRange} from "@/api/client/types";
import {AxiosRequestConfig} from "axios";
import {DemandResponseCurtailmentLevel} from "@/api/client/utility";
import {FlatRateBucket, FLMCohort, RateCode, TOUPricePeriod} from "@/stores/flm/types";
import {camelize} from "humps";

export interface SiteStatus {
  last: DateTime;
  currentSignal: number;
  isOverridden: boolean;
  isOk: boolean;
  errors: Array<string>;
}

export interface LogbookAuthor {
  name: string;
  company: string;
}

export interface LogbookEntryPayload {
  comment: string;
  activePeriod: DateTimeRange;
}

export interface LogbookEntry {
  id: number;
  siteId: number;
  createdById: string;
  createdBy: LogbookAuthor;
  createdAt: DateTime;
  comment: string;
  activePeriod: DateTimeRange;
}

export interface PerformanceCredit {
  year: number;
  month: number | null;
  credit: number;
}

export interface AdjustmentCredit {
  name: string;
  credit: number;
}

export type FLM3TOUComponents = Record<TOUPricePeriod, number>;
export type FLM3FlatComponents = Record<FlatRateBucket, number>;

export interface RatePrices<T> {
  code: RateCode;
  activePeriod: { start: string, end: string };
  data: T;
}

export interface TariffBill<T = Record<string, number>> {
  determinants: T;
  prices: T;
  costs: T;
  extra: Record<string, any>;

  totalCost: number;
}

export interface AccountBill {
  id: number;
  accountId: string;
  aliasId: string;
  cycleStart: DateTime;
  cycleEnd: DateTime;

  baseRate: RateCode;
  baseBill: TariffBill;
  flmRate: RateCode;
  flmCohort: FLMCohort;
  flmBill: TariffBill;

  performanceCredits: Array<PerformanceCredit>;
  adjustmentCredits: Array<AdjustmentCredit>;

  totalAdjustments: number;
  totalBillDelta: number;
  totalFlm2Credits: number;
  totalCredit: number;
}

export interface MonthlyUsageRow {
  dt: DateTime;
  actual: number;
  submeter: number;
  signal: number;
  temperature: number;
}

export interface AccountUsageRow {
  dt: DateTime;
  usage: number | null;
}

export interface EventPerformance {
  eventId: number;
  startDt: DateTime,
  endDt: DateTime,
  intervals: Array<Interval>;
  isCommitted: boolean;
  baseline: number;
  actual: number;
  enrolled: number;
  delta: number;
}

export interface FLM3FlatPerformance {
  date: string;
  singleScore: number;
  cumulativeScore: number;
}

export interface SiteUserRoles {
  canCreateLogbookEntries: boolean;
  canManageEventResponse: boolean;
  canManageLogbook: boolean;
  canManageSettings: boolean;
  canManageUsers: boolean;
  canReceiveNotifications: boolean;
  canViewLogbookEntries: boolean;
}

export interface SiteUser {
  id: string;
  name: string;
  email: string;
  hasNotification: boolean;
  roles: SiteUserRoles;
}

export interface EventLoadsRow {
  dt: DateTime;
  actual: number;
  baseline: number;
  signal: number;
}

export interface SiteForecastRow {
  dt: DateTime;
  forecast: number;
  forecastModified: number;
  forecastPotential: number;
  signal: number;
}

export interface SiteEvent {
  id: number;
  startDt: DateTime,
  endDt: DateTime,
  intervals: Array<{
    interval: Interval,
    level: DemandResponseCurtailmentLevel | null,
  }>;
  isFcm: boolean;
  isTest: boolean;
  isCommitted: boolean | null;

  createdAt: DateTime,
  updatedAt: DateTime,
}


export interface MonthlyLoadFactor {
  month: string;
  avg: number;
  max: number;
  loadFactor: number;
}

export interface LoadFactorResponse {
  monthly: Array<MonthlyLoadFactor>;
  loadFactor: number | null;
}

export interface SiteZoneSchedule {
  hour: DateTime;
  zoneId: number;
  level: number;
}

export interface SiteZoneScheduleResponse {
  schedule: SiteZoneSchedule[];
  updatedAt: DateTime;
}

export interface MalloryForecastRow {
  station: string;
  dt: DateTime;
  stationLocalName: string;
  relativeDay: string;
  skyConditions: string | null;
  precipitation: string | null;
  temperature: string;
  humidity: number;
  wind: string;
  wetBulb: string;
  parsedTemperatureRange: [number, number];
}

export interface DailyEventPrediction {
  date: string;
  windows: Interval[];
}

export interface SiteLoadLevelLimit {
  siteId: number;
  activePeriod: {
    start: string;
    end: string;
  };
  level: number;
  limit: number;
}

export class SiteAPIClient {
  async getStatus(siteId: number, opts?: AxiosRequestConfig): Promise<{
    heartbeat: SiteStatus
  }> {
    const resp = await api.get(
        `/site/${siteId}/status/`,
        opts,
    );
    return resp.data;
  }

  async getUpcomingEvents(siteId: number, opts?: AxiosRequestConfig): Promise<{
    upcomingEvents: Array<SiteEvent>
  }> {
    const resp = await api.get(
        `/site/${siteId}/events/`,
        opts,
    );
    return resp.data;
  }

  async setEventResponse(siteId: number, eventId: number, status: boolean, opts?: AxiosRequestConfig) {
    const resp = await api.put(
        `/site/${siteId}/event/${eventId}/response/`,
        {isCommitted: status},
        opts,
    );
    return resp.data;
  }

  async getPerformances(siteId: number, opts?: AxiosRequestConfig): Promise<{
    events: Array<EventPerformance>
  }> {
    const resp = await api.get<{ events: Array<EventPerformance> }>(
        `/site/${siteId}/performance/`,
        opts,
    );
    return resp.data;
  }

  async getFLM3FlatPerformances(siteId: number, billId: number, opts?: AxiosRequestConfig): Promise<{
    performances: Array<FLM3FlatPerformance>
  }> {
    const resp = await api.get(`/site/${siteId}/performance/${billId}/`, opts);
    return resp.data;
  }

  async getSettings(siteId: number, opts?: AxiosRequestConfig): Promise<{
    settings: SiteConfiguration
  }> {
    const resp = await api.get(
        `/site/${siteId}/settings/`,
        opts,
    );
    return resp.data;
  }

  async updateSettings(siteId: number, newSettings: Partial<SiteConfiguration>, opts?: AxiosRequestConfig): Promise<{
    settings: SiteConfiguration
  }> {
    const resp = await api.patch(
        `/site/${siteId}/settings/`, newSettings,
        opts,
    );
    return resp.data;
  }

  async getProfiles(siteId: number, opts?: AxiosRequestConfig): Promise<{
    profiles: Array<SiteProfile>
  }> {
    const resp = await api.get(
        `/site/${siteId}/profiles/`,
        opts,
    );
    return resp.data;
  }

  async getResponseLevels(siteId: number, opts?: AxiosRequestConfig): Promise<{
    levels: Array<SiteResponseLevel>
  }> {
    const resp = await api.get(
        `/site/${siteId}/response-levels/`,
        opts,
    );
    return resp.data;
  }

  async getUsers(siteId: number, opts?: AxiosRequestConfig): Promise<{
    users: Array<SiteUser>
  }> {
    const resp = await api.get(
        `/site/${siteId}/users/`,
        opts,
    );
    return resp.data;
  }

  async addUser(siteId: number, email: string, opts?: AxiosRequestConfig): Promise<{
    user: SiteUser
  }> {
    const resp = await api.post(
        `/site/${siteId}/users/`,
        {email},
        opts,
    );
    return resp.data;
  }

  async deleteUser(siteId: number, userId: string, opts?: AxiosRequestConfig) {
    const resp = await api.delete(
        `/site/${siteId}/user/${userId}/`,
        opts,
    );
    return resp.data;
  }

  async updateUserRoles(siteId: number, userId: string, newRoles: SiteUserRoles, opts?: AxiosRequestConfig): Promise<{
    user: SiteUser
  }> {
    const resp = await api.patch(
        `/site/${siteId}/user/${userId}/`, newRoles,
        opts,
    );
    return resp.data;
  }

  async getLogbookEntries(siteId: number, opts: AxiosRequestConfig): Promise<{
    entries: Array<LogbookEntry>
  }> {
    const resp = await api.get(
        `/site/${siteId}/logbook/`,
        opts,
    );
    return resp.data;
  }

  async createLogbookEntry(siteId: number, entry: LogbookEntryPayload, opts?: AxiosRequestConfig): Promise<{
    entry: LogbookEntry
  }> {
    const resp = await api.post(
        `/site/${siteId}/logbook/`, entry,
        opts,
    );
    return resp.data;
  }

  async updateLogbookEntry(siteId: number, entryId: number, entry: LogbookEntryPayload, opts?: AxiosRequestConfig): Promise<{
    entry: LogbookEntry
  }> {
    const resp = await api.patch(`/site/${siteId}/logbook/${entryId}/`, entry,
        opts,
    );
    return resp.data;
  }

  async deleteLogbookEntry(siteId: number, entryId: number, opts?: AxiosRequestConfig) {
    const resp = await api.delete(
        `/site/${siteId}/logbook/${entryId}/`,
        opts,
    );
    return resp.data;
  }

  async getBills(siteId: number, opts?: AxiosRequestConfig): Promise<{
    bills: Array<AccountBill>
  }> {
    const resp = await api.get<{ bills: Array<AccountBill>}>(
        `/site/${siteId}/bills/`,
        opts,
    );
    resp.data.bills = resp.data.bills.map((bill) => {
      // make lookup easier by camelizing the bucket, since the prices/costs/etc are camelCase
      if (bill.flmCohort === 'FLM3_FLAT')
        bill.flmBill.extra.bucket = camelize(bill.flmBill.extra.bucket);

      return bill;
    });

    return resp.data;
  }

  async getCurrentBill(siteId: number, accountId: string, opts?: AxiosRequestConfig): Promise<{
    bill: TariffBill,
  }> {
    const resp = await api.get<{bill: TariffBill}>(
        `/site/${siteId}/account/${accountId}/current-bill/`,
        opts,
    );
    if (resp.data.bill.extra?.bucket)
      resp.data.bill.extra.bucket = camelize(resp.data.bill.extra.bucket);

    return resp.data;
  }

  async getMonthlyUsage(siteId: number, month: DateTime, opts?: AxiosRequestConfig): Promise<{
    rows: Array<MonthlyUsageRow>
  }> {
    const url = `/site/${siteId}/monthly-usage/${month.year}/${month.month}/`;
    const resp = await api.get(url, opts);
    return resp.data;
  }

  async getSiteUsage(
      siteId: number,
      startDt: DateTime,
      endDt: DateTime,
      freq: '15T' | '1H' = '1H',
      opts?: AxiosRequestConfig,
  ): Promise<{ rows: Array<AccountUsageRow> }> {
    const url = `/site/${siteId}/usage/${freq}/`;
    const resp = await api.get(url, {
      ...opts,
      params: {startDt, endDt},
    });
    return resp.data;
  }

  async getAccountUsage(
      siteId: number,
      accountId: string,
      startDt: DateTime,
      endDt: DateTime,
      freq: '15T' | '1H' = '1H',
      opts?: AxiosRequestConfig,
  ): Promise<{ rows: Array<AccountUsageRow> }> {
    const url = `/site/${siteId}/account/${accountId}/usage/${freq}/`;
    const resp = await api.get(url, {
      ...opts,
      params: {startDt, endDt},
    });
    return resp.data;
  }

  async getMeterUsage(
      siteId: number,
      meterId: string,
      startDt: DateTime,
      endDt: DateTime,
      freq: '15T' | '1H' = '1H',
      opts?: AxiosRequestConfig,
  ): Promise<{ rows: Array<AccountUsageRow> }> {
    const url = `/site/${siteId}/meter/${meterId}/usage/${freq}/`;
    const resp = await api.get(url, {
      ...opts,
      params: {startDt, endDt},
    });
    return resp.data;
  }

  async getEventLoads(siteId: number, eventId: number, opts?: AxiosRequestConfig): Promise<{
    rows: Array<EventLoadsRow>
  }> {
    const resp = await api.get(
        `/site/${siteId}/event/${eventId}/loads/`,
        opts,
    );
    return resp.data;
  }

  async getSiteForecast(siteId: number, date?: DateTime, opts?: AxiosRequestConfig): Promise<{
    rows: Array<SiteForecastRow>
  }> {
    const url = date
        ? `/site/${siteId}/forecast/${date.toISODate()}/`
        : `/site/${siteId}/forecast/`;
    const resp = await api.get(url, opts);
    return resp.data;
  }


  async getFLMSignals(siteId: number, start: DateTime, end: DateTime, opts?: AxiosRequestConfig): Promise<{
    rows: Array<{ dt: DateTime, signal: number }>;
  }> {
    const resp = await api.get(`/site/${siteId}/signals/`, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }

  async getSubmeterReadings(
      siteId: number,
      submeterFieldId: number,
      start: DateTime,
      end: DateTime,
      opts?: AxiosRequestConfig,
  ): Promise<{
    rows: Array<{ dt: DateTime, usage: number | null }>
  }> {
    const resp = await api.get(`/site/${siteId}/submeter-field/${submeterFieldId}/readings/`, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }


  async getLoadFactor(siteId: number, accountId: string, month: DateTime, opts?: AxiosRequestConfig): Promise<LoadFactorResponse> {
    const url = `/site/${siteId}/account/${accountId}/load-factor/${month.toISODate()}/`;
    const resp = await api.get(url, opts);
    return resp.data;
  }

  async getRatePrices(siteId: number, accountId: string, date: DateTime, opts?: AxiosRequestConfig): Promise<{
    prices: Array<RatePrices<unknown>>
  }> {
    const url = `/site/${siteId}/account/${accountId}/rates/${date.toISODate()}/`;
    const resp = await api.get(url, opts);
    return resp.data;
  }

  async getZoneSchedule(siteId: number, start?: DateTime, end?: DateTime, opts?: AxiosRequestConfig): Promise<SiteZoneScheduleResponse> {
    const resp = await api.get(`/site/${siteId}/zone-schedule/`, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }

  async exportZoneSchedule(siteId: number, opts?: AxiosRequestConfig): Promise<Blob> {
    const resp = await api.get(`/site/${siteId}/zone-schedule/export/`, {
      ...opts,
      responseType: 'blob',
    });
    return resp.data;
  }

  async saveZoneSchedule(siteId: number, data: SiteZoneSchedule[]): Promise<SiteZoneScheduleResponse> {
    const resp = await api.post(`/site/${siteId}/zone-schedule/`, {
      schedule: data,
    });
    return resp.data;
  }

  async getSiteLoadLevelLimits(siteId: number, opts?: AxiosRequestConfig): Promise<{
    limits: SiteLoadLevelLimit[];
  }> {
    const resp = await api.get(`/site/${siteId}/load-level-limits/`, opts);
    return resp.data;
  }

  async getMalloryForecast(siteId: number, opts?: AxiosRequestConfig): Promise<{
    rows: MalloryForecastRow[];
  }> {
    const resp = await api.get(`/site/${siteId}/mallory-forecast/`, opts);
    return resp.data;
  }

  async getPotentialEvents(siteId: number, opts?: AxiosRequestConfig): Promise<{
    events: Array<DailyEventPrediction>
  }> {
    const resp = await api.get(`/site/${siteId}/event-predictions/`, opts);
    return resp.data;
  }
}
